import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Row, Col } from 'react-bootstrap';
import { BookReviewCard } from './styled';

const BookReviewSummary = ({ frontmatter }) => {
  const {
    title,
    description,
    rating,
    hero_image,
    amazon_affiliate,
    book_depo_affiliate,
  } = frontmatter;

  const hero_image_data = getImage(hero_image);
  return (
    <BookReviewCard>
      <Row>
        <Col xs={5} md={3}>
          <GatsbyImage alt={title} image={hero_image_data} />
        </Col>
        <Col md={9} xs={7} className="summary">
          <h4>{title}</h4>
          <p className="description">{description}</p>
          <p className="rating">My rating: &nbsp;
            {[...Array(rating)].map((star) => {        
              return (         
                <span className="star star-light">&#9733;</span>        
              );
            })}
            {[...Array(5-rating)].map((star) => {        
              return (         
                <span className="star star-dark">&#9733;</span>        
              );
            })}

          </p>
          { amazon_affiliate && <p className="amazon"><a href={amazon_affiliate} rel="noreferrer" target="_blank">Buy from Amazon</a></p> }
          { book_depo_affiliate && <p className="book_depo"><a href={book_depo_affiliate} rel="noreferrer" target="_blank">Buy from Book Depository</a></p> }
        </Col>
      </Row>
    </BookReviewCard>
  );
}

export default BookReviewSummary;

